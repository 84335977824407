import { ToolbarNavItem } from '@app/shared/components/toolbar-nav/toolbar-nav-item';
import { UserRole } from '@app/shared/models/user-role.model';

export function createNavigationItems(userRole?: UserRole): ToolbarNavItem[] {
    switch (userRole) {
        case UserRole.ADMIN:
            return [
                {
                    label: 'Benutzer',
                    url: '/users',
                },
                {
                    label: 'Häufige Fragen',
                    url: '/faq',
                },
            ];

        case UserRole.WINERY_USER:
            return [
                {
                    label: 'Selbstbewertung',
                    url: '/certification',
                },
                {
                    label: 'Häufige Fragen',
                    url: '/faq',
                },
            ];

        default:
            return [];
    }
}
