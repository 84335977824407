import { Routes } from '@angular/router';
import { APP_TITLE } from '@app/app/services/app-title.service';

const routes: Routes = [
    {
        path: 'faq',
        loadComponent: () => import('./faq/faq.page').then((m) => m.FaqPage),
        data: {
            [APP_TITLE]: 'Häufige Fragen',
        },
    },
];

export default routes;
