<footer>
    <section class="info">
        <div class="copyright"
             i18n="@@footer.copyright">© {{currentYear}} Österreichischer Weinbauverband</div>
        <div class="version"
             i18n="@@footer.version">Version: {{ version }}</div>
    </section>

    <section class="webmozarts">
        <a href="https://webmozarts.com" target="_blank" rel="noopener"> Entwickelt von
            <img class="logo" src="assets/img/webmozarts-logo.png" alt="Webmozarts Logo"/>
            <img class="text" src="assets/img/webmozarts-text.svg" alt="Webmozarts"/>
        </a>
    </section>

    <section class="links">
        <nav>
            <a href="https://wein-nachhaltig.de/datenschutz/"
               rel="noopener"
               target="_blank">
                Datenschutz
            </a>

            <a href="https://wein-nachhaltig.de/impressum/"
               rel="noopener"
               target="_blank">
                Impressum
            </a>
        </nav>
    </section>
</footer>
